<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <div class="about-wrapper-detail">
        <h1 class="header-big">Помощь</h1>
        <div class="about-detail-content">
          <p>Самые частозадаваемые вопросы</p>
        </div>
      </div>
    </div>
    <f-a-q-block />
  </div>
</template>

<script>
import { WOW } from "wowjs";
import FAQBlock from "@/components/page/faq/FAQBlock";
export default {
  name: "FAQ",
  components: { FAQBlock },
  mounted() {
    new WOW({ offset: 140, live: false }).init();
  }
};
</script>

<style scoped></style>
