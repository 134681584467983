<template>
  <site-section section-class="help-wrapper">
    <template v-slot:content>
      <div class="flex-help">
        <div class="help-block">
          <q-a-block />
        </div>
        <div class="form-block">
          <div class="form-block-wr wow fadeInUp">
            <p>
              Не нашли нужный ответ в списке? Задайте свой вопрос в форму ниже.
            </p>
            <form @submit.prevent="sendQuestion">
              <input
                type="text"
                name="name"
                placeholder="Как Вас зовут? *"
                v-model="formData.fio"
                required
              />
              <input
                type="email"
                name="phone"
                placeholder="Электронная почта *"
                v-model="formData.email"
                required
              />
              <textarea
                placeholder="Ваш вопрос *"
                v-model="formData.question"
                name="text"
                required
              />
              <label class="soglasie">
                <input v-model="formData.agreed" type="checkbox" />
                <span>
                  Согласен на обработку персональных данных
                </span>
              </label>
              <input
                type="submit"
                value="Отправить"
                :disabled="!formData.agreed"
              />
            </form>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:header>
      <modal-alert
        :show-modal.sync="showAlert"
        :alert-text="alertText"
        :action-modal="closeAlert"
        action-text="Продолжить"
      />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import QABlock from "@/components/page/faq/QABlock";
import ModalAlert from "@/components/page/modals/forms/ModalAlert";
export default {
  name: "FAQBlock",
  data() {
    return {
      showAlert: false,
      alertText: "",
      formData: {
        fio: "",
        email: "",
        question: "",
        agreed: false
      }
    };
  },
  methods: {
    sendQuestion() {
      this.axios.post("/email/question", this.formData).then(response => {
        const data = response.data;
        if (data) {
          this.alertText = "Ваш вопрос отправлен. Ожидайте ответ на Ваш email.";
          this.formData = {};
        } else {
          this.alertText = "Произошла ошибка";
        }
        this.showAlert = true;
      });
    },
    closeAlert() {
      this.showAlert = false;
    }
  },
  components: { QABlock, SiteSection, ModalAlert }
};
</script>

<style lang="scss" scoped>
.form-block-wr {
  padding: 20px 25px;
  p {
    text-align: left;
  }
}

.form-block input[type="submit"] {
  margin-top: 30px;
}

.soglasie input ~ span {
  line-height: 1.5;
}
</style>
