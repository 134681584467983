<template>
  <div class="grid">
    <vsa-list transition="fade" :auto-collapse="false" class="accordion">
      <vsa-item class="wow fadeInUp" v-for="qa in qas" v-bind:key="qa.id">
        <vsa-heading v-ht>{{ qa.question }}</vsa-heading>
        <vsa-icon>
          <div class="open index-accordeon-icon">+</div>
          <div class="close index-accordeon-icon">+</div>
        </vsa-icon>
        <vsa-content><p v-html="qa.answer"></p></vsa-content>
      </vsa-item>
    </vsa-list>
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from "vue-simple-accordion";
export default {
  name: "QABlock",
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
  },
  data() {
    return {
      qas: [
        {
          id: 0,
          question: "Как подключить подписку",
          answer:
            "Нажмите кнопку «Войти» на главной странице <a href='https://thelak.com'>thelak.com</a> На странице авторизации введите данные для входа, если у Вас есть личный кабинет. Если личный кабинет зарегистрирован не был, нажмите на кнопку «Зарегистрируйтесь». Заполните необходимые поля в соответсвии с формой. После регистрации нажмите «Подключить подписку» и выберите подходящий срок действия подписки, далее нажмите «подключить подписку» и на открывшейся странице введите данные банковской карты для оплаты."
        },
        {
          id: 1,
          question: "Как отключить автопродление подписки",
          answer:
            "Зайдите в свой личный кабинет и снимите галочку с чек-бокса «Автопродление подписки»"
        },
        {
          id: 2,
          question: "Как купить сертификат",
          answer:
            "Через главное меню <a href='https://thelak.com'>thelak.com</a> перейдите в раздел «Сертификаты». В открывшемся разделе сайта выберите срок действия подписки по сертификату, нажмите на кнопку «Подарить сертификат», в открывшихся полях введите имя владельца сертификата и  комментарий — например, поздравление. После оплаты сертификат можно распечатать или отправить на электронную почту."
        },
        {
          id: 4,
          question: "Как активировать промкод",
          answer:
            "Промокод можно ввести после авторизации в Личном кабинете и перехода в раздел «Промокод» из нижнего меню главной страницы <a href='https://thelak.com'>thelak.com</a> или через раздел промокод в Вашем Личном кабинете"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "~vue-simple-accordion/dist/vue-simple-accordion.css";
p {
  color: #fff !important;
}
.vsa-item {
  margin-bottom: 10px;
  background-color: #46494e;
  font-weight: 400 !important;
  border-radius: 4px;
  &__heading,
  &__trigger {
    text-align: left;
  }
  a {
    color: #4849ea;
    &:hover {
      color: #3435e6;
    }
  }

  transition: all 0.5s ease;
  &--is-active {
    .vsa-item__trigger__icon {
      .open {
        display: none;
      }
      .close {
        display: block;
      }
    }
  }
  &__trigger__content {
    @media screen and (max-width: 376px) {
      font-size: 0.85rem;
    }
    @media screen and (max-width: 361px) {
      font-size: 0.845rem;
    }
    @media screen and (max-width: 321px) {
      font-size: 0.9rem;
    }
  }
  &__trigger__icon {
    .open {
      display: block;
      @media screen and (max-width: 769px) {
        right: 7px;
      }
    }
    .close {
      display: none;
      @media screen and (max-width: 769px) {
        right: 7px;
      }
    }
  }
}

.vsa-list {
  --vsa-bg-color: none;
  --vsa-text-color: #fff;
  --vsa-highlight-color: none;
  --vsa-border-color: #fff !important;
  --vsa-border-style: none;
  --vsa-content-padding: 2rem;

  @media screen and (max-width: 769px) {
    --vsa-heading-padding: 1em;
    --vsa-max-width: 100%;
    --vsa-min-width: inherit;
  }

  --vsa-heading-padding: 1.8em;
  cursor: pointer;
}
</style>
